import _ from "lodash";
import binlookup from "binlookup";
import {push} from "connected-react-router";
import {startSubmit, stopSubmit, startAsyncValidation, stopAsyncValidation} from "redux-form";
import {call, put, select} from "redux-saga/effects";

import jagapi from "../../axios/jagapi";
import webapi from "../../axios/webapi";
import loanapi from "../../axios/loanapi";
import * as constant from "../../shared/constant/constant";
import * as toastMessages from "../../shared/constant/toastMessages";
import * as field from "../../shared/constant/field";

import * as errorMessages from "../../shared/constant/errorMessages";
import {
    DASHBOARD as dashField,
    LOAN as loanField
} from "../../shared/constant/field";
import * as form from "../../shared/constant/form";
import * as route from "../../shared/constant/route";
import {getIpAddress} from "../../shared/utility/ipify";
import * as utility from "../../shared/utility/utility";
import {getConsentContentSuccess} from "../action/ContentAction";
import * as createAndTrackToastMessageAction from "../action/CreateAndTrackToastMessageAction";
import * as actions from "../action/DashboardAction";
import * as errorActions from "../action/ErrorAction";
import * as loanActions from "../action/loanActions";
import * as authActions from "../action/AuthAction";
import {setOrganizationContent} from "../action/OrganizationActions";
import * as trackingActions from "../action/trackingActions";
import api from "../../axios/api";
import {LoanType} from "../../shared/constant/loan";
import hotboxapi from "../../axios/hotboxapi";
import * as apiErrorCodes from "../../shared/constant/apiErrorCodes";
import {API_ERROR_MESSAGE} from "../../shared/constant/apiErrorMessage";
import * as insuranceActions from "../action/InsuranceAction";
import {isForaCredit} from "../../shared/utility/utility";

let _ip = null;

export function* getPersonalInformation(action) {
    try {
        let getProfileUrl = route.WEB_API_USER_GET_PROFILE.replace(/:id/i, action.payload.id).replace(
            /:organizationId/,
            utility.getOrganizationId()
        );
        const response = yield call(webapi, {
            method: "get",
            url: getProfileUrl
        });

        if (response && response.data && response.data.state) {
            yield put(setOrganizationContent({state: response.data.state}));
        }
        yield put(actions.getPersonalInformationSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

export function* saveCommunicationPrefs(action) {
    try {
        let patchNotificationsUrl = route.WEB_API_USER_PATCH_NOTIFICATIONS.replace(/:id/i, action.payload.id);

        let data = _.mapValues(action.payload.prefs, v => (v ? 1 : 0));
        yield call(webapi, {
            method: "patch",
            url: patchNotificationsUrl,
            data: {
                organizationId: 0,
                notifications: data
            }
        });
        yield put(actions.saveCommunicationPrefsSuccess());
        yield put(errorActions.clearApiError(null, field.ERROR_SAVE_COMM_PREFS));
        yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage("Preferences Saved"));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SAVE_COMM_PREFS));
    }
}

export function* getStatements(action) {
    try {
        let getStatementsUrl = route.LOAN_API_STATEMENTS.replace(/:loanId/i, action.payload.loanId);

        const response = yield call(jagapi, {
            method: "get",
            url: getStatementsUrl
        });
        yield put(actions.getStatementsSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

export function* isDebitCard(action) {
    try {
        yield put(startAsyncValidation(action.payload.form));
        let lookup = binlookup({mode: "no-cors"});
        const data = yield lookup(action.payload.debitCard.replace(/\s+/g, ""));
        if (data.type === "debit") {
            yield put(stopAsyncValidation(action.payload.form, {}));
        } else {
            yield put(
                stopAsyncValidation(action.payload.form, {
                    [action.payload.field]: errorMessages.INVALID_CARD_NUMBER
                })
            );
        }
    } catch (error) {
        yield put(
            stopAsyncValidation(action.payload.form, {[action.payload.field]: errorMessages.INVALID_CARD_NUMBER})
        );
    }
}

export function* getSignedAgreements(action) {
    try {
        let uri;
        uri = route.JAG_API_LOAN_DOCS_SIGNED_AGREEMENTS.replace(/:id/i, action.payload.userId);
        uri = uri.replace(/:loanId/i, action.payload.baseLoanId);

        const response = yield call(jagapi, {
            method: "get",
            url: uri
        });
        yield put(actions.getSignedAgreementsSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

export function* getAgreementDetails(action) {
    let agreementDetailURL = "";
    try {
        if (action.payload.docStateId) {
            agreementDetailURL = route.JAG_API_LOAN_DOCS_SIGNED_AGREEMENT_STATE_VERSION.replace(
                /:id/i,
                action.payload.id
            )
                .replace(/:loanId/i, action.payload.loanId)
                .replace(/:docStateId/i, action.payload.docStateId)
                .replace(/:docVersionId/i, action.payload.docVersionId);
        } else {
            agreementDetailURL = route.JAG_API_LOAN_DOCS_SIGNED_AGREEMENT_VERSION.replace(/:id/i, action.payload.id)
                .replace(/:loanId/i, action.payload.loanId)
                .replace(/:docStateId/i, action.payload.docStateId);
        }

        const response = yield call(jagapi, {
            method: "get",
            url: agreementDetailURL
        });
        yield put(errorActions.clearApiError(null, field.ERROR_GET_AGREEMENT_DETAILS));
        yield put(actions.getAgreementDetailsSuccess(response));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_AGREEMENT_DETAILS)
        );
    }
}

export function* getActionItems(action) {
    try {
        let getActionItemsUrl = route.JAG_API_LOANS_CHECKLIST.replace(/:loanId/i, action.payload.loanId);

        const response = yield call(jagapi, {
            method: "get",
            url: getActionItemsUrl
        });

        let result = {
            [dashField.TODO_BANK_VERIFICATION]: null,
            [dashField.TODO_VERIFY_PAIDOFF_PRIOR_LOANS]: null,
            [dashField.TODO_VERIFY_PREVIOUS_LOAN_PAIDOFF]: null,
            [dashField.TODO_EMPLOYMENT_VERIFICATION]: null,
            [dashField.TODO_REFERENCES]: null,
            [dashField.TODO_ESIG]: null,
            [dashField.TODO_COLLECT_PAYMENT_INFO]: null,
            [dashField.TODO_SPOUSAL_INFORMATION]: null,
            [dashField.TODO_EMAIL_VERIFICATION]: null
        };

        if (response.data && response.data.toDoListItems) {
            response.data.toDoListItems.forEach(val => {
                if (val.toDoName === constant.TODO_NAME_BANK_VERIFICATION) {
                    result[dashField.TODO_BANK_VERIFICATION] = val;
                } else if (val.toDoName === constant.TODO_NAME_VERIFY_PAIDOFF_PRIOR_LOANS) {
                    result[dashField.TODO_VERIFY_PAIDOFF_PRIOR_LOANS] = val;
                } else if (val.toDoName === constant.TODO_NAME_VERIFY_PREVIOUS_LOAN_PAID_OFF) {
                    result[dashField.TODO_VERIFY_PREVIOUS_LOAN_PAIDOFF] = val;
                } else if (val.toDoName === constant.TODO_NAME_DIRECT_MAIL_BANK_VERIFICATION) {
                    result[dashField.TODO_BANK_VERIFICATION] = val;
                } else if (val.toDoName === constant.TODO_NAME_EMPLOYMENT_VERIFICATION) {
                    result[dashField.TODO_EMPLOYMENT_VERIFICATION] = val;
                } else if (val.toDoName === constant.TODO_NAME_REFERENCES) {
                    result[dashField.TODO_REFERENCES] = val;
                } else if (val.toDoName === constant.TODO_NAME_ESIG) {
                    result[dashField.TODO_ESIG] = val;
                } else if (val.toDoName === constant.TODO_SPOUSAL_INFORMATION) {
                    result[dashField.TODO_SPOUSAL_INFORMATION] = val;
                } else if (val.toDoName === constant.TODO_EMAIL_VERIFICATION) {
                    result[dashField.TODO_EMAIL_VERIFICATION] = val;
                } else if (val.toDoName === constant.TODO_NAME_ACCOUNT_OWNERSHIP) {
                    result[dashField.TODO_ACCOUNT_OWNERSHIP] = val;
                } else if (val.toDoName === constant.TODO_NAME_ACCOUNT_NUMBER) {
                    result[dashField.TODO_ACCOUNT_NUMBER] = val;
                } else {
                    result[val.toDoName] = val;
                }
            });
        }

        if (response.data && response.data.isTodoCollectPaymentInfo) {
            result[dashField.TODO_COLLECT_PAYMENT_INFO] = response.data.isTodoCollectPaymentInfo;
        }

        /* Redirect Fora customers to the Payment Protection Plan page if
        *  the current page is sign loan docs page and
        *  if all the action items are completed including Payment info
        * */
        if(isForaCredit() &&
            !response.data.toDoListItems.some((actionItem) => actionItem.completionTime === null) &&
            !response.data.isTodoCollectPaymentInfo
            ){
            let isInsuranceSupported = yield select(state => state[field.REDUCER_NODE_INSURANCE][field.IS_INSURANCE_SUPPORTED]);
            if(window.location.pathname === route.DASHBOARD_LOAN_DOCS_PAGE && isInsuranceSupported){
                yield put(push({
                    pathname: route.DASHBOARD_PAYMENT_PROTECTION_PLAN_PAGE, state: {isPaymentProtectionPlan: true}
                }));
            }
            yield put(insuranceActions.updateActionItemCompleted());
        }
        yield put(actions.getActionItemsSuccess(result));
        yield put(actions.setTagsForActionItemsSuccess(result));
    } catch (error) {
        console.log(error);
    }
}

export function* saveRequestDetails(action) {
    try {
        const customerId = yield select(state => state[field.REDUCER_NODE_AUTH][field.CUSTOMER_ID]);
        const data = Object.assign({subDomain: window.location.origin}, action.payload.reqData);
        const url = route.JAG_API_SAVE_REQUEST_DETAILS
            .replace(":customerId", customerId)
            .replace(":organizationId", utility.getOrganizationId());

        yield put(actions.setShowLoader());
        const response = yield call(jagapi, {method: "put", url, data});
        yield put(actions.unsetShowError());
        yield put(actions.saveRequestDetailsSuccess(response));
        if (action.payload.nextAction) {
            action.payload.nextAction();
        }
    } catch {
        yield put(actions.unsetShowError());
        yield put(actions.saveRequestDetailsFailure());
    }
}

export function* checkBVDataReady(action) {
    try {

        const url = route.JAG_API_CUSTOMER_CHECK_BV_DATA_READY
            .replace(":bvRequestId", action.payload.bvRequestId)
            .replace(":bvSystemId", action.payload.bvSystemId);

        yield put(actions.setShowLoader());
        const response = yield call(jagapi, {
            method: "get",
            url: url
        });

        const result = response.data;
        yield put(actions.unsetShowLoader());
        yield put(actions.checkBVDataReadySuccess(result));
    } catch (error) {
        console.log(error);
        yield put(actions.unsetShowLoader());
        yield put(actions.checkBVDataReadyFailure());
    }
}

export function* getPollParameters(action) {
    try {
        const organizationId = utility.getOrganizationId();

        const url = route.JAG_API_CUSTOMER_GET_POLL_PARAMS
            .replace(":customerId", action.payload.customerId)
            .replace(":organizationId", organizationId)
            .replace(":bvRequestId", action.payload.bvRequestId)
            .replace(":bvSystemId", action.payload.bvSystemId);

        yield put(actions.setShowLoader());
        const response = yield call(jagapi, {
            method: "get",
            url: url
        });

        const result = response.data;
        yield put(actions.unsetShowLoader());
        yield put(actions.getPollParametersSuccess(result));
    } catch (error) {
        console.log(error);
        yield put(actions.unsetShowLoader());
        yield put(actions.getPollParametersFailure());
    }
}

export function* checkBVConnectionStatus(action) {
    try {
        const url = route.JAG_API_CUSTOMER_CHECK_BV_CONN_STATUS
            .replace(":bvRequestId", action.payload.bvRequestId)
            .replace(":bvSystemId", action.payload.bvSystemId);

        yield call(jagapi, {
            method: "post",
            url: url
        });
    } catch (error) {
        // Do nothing.
    }
}

export function* getBankVerificationItems(action) {
    try {
        const customerId = yield select(state => state[field.REDUCER_NODE_AUTH][field.CUSTOMER_ID]);
        const stateCode = yield select(
            state =>
                state[field.REDUCER_NODE_APPLICATION][field.USERAPPINFO][field.STATE] ||
                state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.PERSONAL_INFO][field.DASHBOARD.STATE]
        );
        const organizationId = utility.getOrganizationId();

        const payload = {
            customerId,
            organizationId,
            stateCode,
            subDomain: window.location.origin
        };
        yield put(actions.setShowLoader());
        const response = yield call(jagapi, {
            method: "put",
            url: route.JAG_API_BANK_VERIFICATON_CHECKLIST,
            data: payload
        });

        const result = {
            [dashField.BANK_VERIFICATION_STATUS]: false,
            [dashField.SHOW_VERIFY_MY_ACCOUNT_BUTTON]: false,
            [dashField.DECISION_LOGIC_URL]: null,
            [dashField.DECISION_LOGIC_STATUS]: null,
            [dashField.DECISION_LOGIC_DETAILED_STATUS_INFO]: null,
            [dashField.BV_SYSTEM_ID]: null,
            [dashField.BV_SYSTEM_NAME]: null,
            [dashField.BV_SYSTEM_URL]: null,
            [dashField.MAXIMUM_RETRY]: null,
            [dashField.BV_REQUEST_ID]: null
        };
        result[dashField.DECISION_LOGIC_DETAILED_STATUS_INFO] = response.data.detailedStatusInfo;
        if (_.includes(response.data, constant.NOT_VERIFIED)) {
            //show Verify Bank Account button
            result[dashField.BANK_VERIFICATION_STATUS] = false;
            result[dashField.SHOW_VERIFY_MY_ACCOUNT_BUTTON] = true;
            result[dashField.DECISION_LOGIC_URL] = response.data.decisionLogicURL;
            result[dashField.DECISION_LOGIC_STATUS] = response.data.status;
            result[dashField.BV_SYSTEM_ID] = response.data.bvSystemId;
            result[dashField.BV_SYSTEM_NAME] = response.data.bvSystemName;
            result[dashField.BV_SYSTEM_URL] = response.data.bvSystemUrl;
            result[dashField.MAXIMUM_RETRY] = response.data.maximumRetry;
            result[dashField.BV_REQUEST_ID] = response.data.bvRequestId;
        } else if (_.includes(response.data, constant.DL_ERROR)) {
            //show customer care service number
            result[dashField.BANK_VERIFICATION_STATUS] = false;
            result[dashField.SHOW_VERIFY_MY_ACCOUNT_BUTTON] = false;
            result[dashField.DECISION_LOGIC_URL] = null;
            result[dashField.DECISION_LOGIC_STATUS] = null;
            result[dashField.BV_SYSTEM_ID] = response.data.bvSystemId;
            result[dashField.BV_SYSTEM_NAME] = response.data.bvSystemName;
            result[dashField.BV_SYSTEM_URL] = response.data.bvSystemUrl;
            result[dashField.MAXIMUM_RETRY] = response.data.maximumRetry;
            result[dashField.BV_REQUEST_ID] = response.data.bvRequestId;
        } else {
            //Verified bank info
            result[dashField.BANK_VERIFICATION_STATUS] = true;
            result[dashField.SHOW_VERIFY_MY_ACCOUNT_BUTTON] = false;
            result[dashField.DECISION_LOGIC_URL] = null;
            result[dashField.DECISION_LOGIC_STATUS] = response.data.status;
            result[dashField.BV_SYSTEM_ID] = response.data.bvSystemId;
            result[dashField.BV_SYSTEM_NAME] = response.data.bvSystemName;
            result[dashField.BV_SYSTEM_URL] = response.data.bvSystemUrl;
            result[dashField.MAXIMUM_RETRY] = response.data.maximumRetry;
            result[dashField.BV_REQUEST_ID] = response.data.bvRequestId;
        }
        yield put(actions.unsetShowLoader());
        yield put(actions.getBankVerificationItemsSuccess(result));
    } catch (error) {
        console.log(error);
        yield put(actions.unsetShowLoader());
    }
}

export function* getBankVerificationConfirmed(action) {
    try {
        let getBankVerificationConfirmedUrl = route.WEB_API_GET_BANK_VERIFICATION_CONFIRMED.replace(
            /:baseLoanId/i,
            action.payload.loanId
        );

        const response = yield call(webapi, {
            method: "get",
            url: getBankVerificationConfirmedUrl
        });
        yield put(actions.getBankVerificationConfirmedSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

export function* getAchDetails(action) {
    try {
        let baseLoanId = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN_SUMMARY][field.LOAN.BASE_LOAN_ID]
        );
        let getAchDetailsUrl = route.JAG_API_GET_ACH_DETAILS.replace(/:baseLoanId/i, baseLoanId);
        const response = yield call(jagapi, {
            method: "get",
            url: getAchDetailsUrl
        });

        yield put(actions.getAchDetailsSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

export function* verifyEffectiveDate(action) {
    try {
        let baseLoanId = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN_SUMMARY][field.LOAN.BASE_LOAN_ID]
        );
        let loanType = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_TYPE]
        );
        let stateKey = yield select(
            state => state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.PERSONAL_INFO][field.DASHBOARD.STATE]
        );
        let nextPage = action.payload.nextPage;
        let verifyEffectiveDateUrl = route.JAG_API_VERIFY_EFFECTIVE_DATE.replace(/:baseLoanId/i, baseLoanId);
        const response = yield call(jagapi, {
            method: "get",
            url: verifyEffectiveDateUrl
        });

        const isMKWI =
            utility.getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID && stateKey === constant.STATE_WISCONSIN;

        if (response.data.isEffectiveDateValid === false && !isMKWI && loanType !== LoanType.LINE_OF_CREDIT) {
            yield put(trackingActions.verifyEffectiveDate(baseLoanId, false));
            yield put(actions.reexpress(baseLoanId, nextPage));
        } else {
            yield put(trackingActions.verifyEffectiveDate(baseLoanId, true));
            yield put(
                push({
                    pathname: action.payload.nextPage
                })
            );
            yield put(errorActions.clearApiError(null, field.ERROR_VERIFY_EFFECTIVE_DATE));
        }
    } catch (error) {
        console.log(error);
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_VERIFY_EFFECTIVE_DATE)
        );
    }
}

export function* updateCustomerInfoAndReExpress(action) {
    try {
        let updateCustomerInfoAndReexpressUrl = route.JAG_API_CUSTOMER_UPDATE_INFO_AND_REEXPRESS.replace(
            /:customerId/i,
            action.payload.customerId
        ).replace(/:baseLoanId/i, action.payload.loanId);
        yield put(actions.setShowLoader());
        yield call(jagapi, {
            method: "post",
            url: updateCustomerInfoAndReexpressUrl
        });
        yield call(getActionItems, action);
        yield call(getBankVerificationConfirmed, action);
        yield put(actions.unsetShowLoader());
        yield put(push(route.DASHBOARD_SIGNAGREEMENT));
        yield put(errorActions.clearApiError(null, field.ERROR_UPDATE_CUSTOMER_PAYROLL_AND_BANK_INFO));
    } catch (error) {
        console.log(error);
        yield put(actions.unsetShowLoader());
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(
                error,
                field.ERROR_UPDATE_CUSTOMER_PAYROLL_AND_BANK_INFO
            )
        );
    }
}

export function* reexpress(action) {
    try {
        let reexpresseUrl = route.JAG_API_REEXPRESS.replace(/:baseLoanId/i, action.payload.loanId);
        const response = yield call(jagapi, {
            method: "post",
            url: reexpresseUrl
        });
        if (action.payload.nextPage && action.payload.nextPage !== null) {
            yield put(
                push({
                    pathname: action.payload.nextPage
                })
            );
        }
        if (response.data.data !== null) {
            yield put(trackingActions.loanReexpressed());
        } else {
            yield put(trackingActions.reexpressError(response.data.errors[0].errorCode));
        }

        yield put(errorActions.clearApiError(null, field.ERROR_REEXPRESS));
    } catch (error) {
        console.log(error);
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_REEXPRESS));
    }
}

export function* initiateDraw(action) {
    yield put(startSubmit(form.DRAW_REQUEST));

    try {
        yield call(getAchDetails, action);
        let initiateDrawUrl = route.JAG_API_CUSTOMER_INITIATE_DRAW;

        let nextEffectiveDate = yield select(
            state =>
                state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.CREDIT_PAYMENT_BATCH_SCHEDULE][
                    field.DASHBOARD.ACH_EFFECTIVE_DATE
                ]
        );

        const response = yield call(jagapi, {
            method: "post",
            url: initiateDrawUrl,
            data: {
                baseLoanId: action.payload.baseLoanId,
                drawAmount: action.payload.drawAmount,
                effectiveDate: nextEffectiveDate,
                subIdTrackingId: action.payload.subIdTrackingId ? action.payload.subIdTrackingId : null
            }
        });

        let result = {
            [loanField.SCHEDULED_DATE]: action.payload.effectiveDate,
            [loanField.DRAW_AMOUNT]: action.payload.drawAmount,
            [loanField.DRAW_SCHEDULED]: response.status === constant.REQUEST_SUCCESS
        };
        yield put(trackingActions.submitDrawRequestPage());
        yield put(actions.initiateDrawSuccess(result));
        yield put(
            push({
                pathname: route.DASHBOARD_DRAW_RESULT_PAGE,
                state: {showMoneyOnThwWayImage: true}
            })
        );
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
    yield put(stopSubmit(form.DRAW_REQUEST));
}

export function* getStatementDetails(action) {
    try {
        let getStatementDetailUrl = route.LOAN_API_STATEMENT_DETAILS.replace(/:loanId/i, action.payload.loanId).replace(
            /:statementId/i,
            action.payload.statementId
        );

        const response = yield call(jagapi, {
            method: "get",
            url: getStatementDetailUrl
        });

        yield put(errorActions.clearApiError(null, field.ERROR_GET_STATEMENT_DETAILS));
        yield put(actions.getStatementDetailsSuccess(response));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_STATEMENT_DETAILS)
        );
    }
}

export function* getStatementPDF(action) {
    try {
        let getStatmentPDFUrl = route.JAG_API_STATEMENT_PDF.replace(/:loanId/i, action.payload.loanId)
            .replace(/:statementId/i, action.payload.statementId);

        const response = yield call(jagapi, {
            responseType: "blob",
            method: "get",
            url: getStatmentPDFUrl
        });

        const pdfData = response.data;

        const pdfBlob = new Blob([pdfData], {type: 'application/pdf'});
        const link = URL.createObjectURL(pdfBlob);
        let downloadWindow = window.open(link, '_blank');

        if (downloadWindow == null) {
            yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(utility.getAPIErrorPayload(errorMessages.ENABLE_POP_UP)));
        } else {
            yield put(trackingActions.viewedStatementPDF());
        }

    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_STATEMENT_PDF));
    }
}

export function* getLoanAgreementErrorPopUpMessage() {
    try {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(utility.getAPIErrorPayload(errorMessages.ENABLE_POP_UP)));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_LOAN_AGREEMENT_PDF));
    }
}

export function* getHistory() {
    let _stateSelector = state => ({
        userId: state[field.REDUCER_NODE_AUTH][field.USERID]
    });

    try {
        const _state = yield select(_stateSelector);

        let url = route.LOAN_API_HISTORY.replace(/:userId/i, _state.userId);
        const response = yield call(jagapi, {
            method: "get",
            url: url
        });

        yield put(errorActions.clearApiError(null, field.ERROR_GET_HISTORY));
        yield put(actions.getHistorySuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_GET_HISTORY));
    }
}

export function* submitReloan(action) {
    try {
        yield put(startSubmit(form.RELOAN));
        let data = yield select(state => ({
            requestedLoanAmount: action.payload.loanAmount,
            promoCode: action.payload.promoCode,
            tierKey: state[field.REDUCER_NODE_APPLICATION][field.USERAPPMISC][field.TIERKEY],
            organizationId:
                state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_ID],
            isOrganic: true,
            isShortForm: false,
            customerId: state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_CUSTOMERID],
            buildProfile:
                state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE],
            userId: state[field.REDUCER_NODE_AUTH][field.USERID],
            subIdTrackingId: state[field.REDUCER_NODE_APPLICATION][field.SUB_ID_TRACKING][field.ID]
        }));

        if (!data.subIdTrackingId) {
            data.subIdTrackingId = null;
        }

        if (!_ip) {
            try {
                _ip = yield getIpAddress();
            } catch (err) {}
        }

        data["ipAddress"] = _ip;

        let HBresponse = yield call(webapi, {
            method: "post",
            url: route.WEB_API_RELOAN_HB_CHECK,
            data
        });

        if (HBresponse.data.Response.Result === constant.HOTBOX_ACCEPT) {
            yield put(loanActions.updateActiveLoanDetails(HBresponse));

            let maxLoanAmount = yield select(
                state => state[field.REDUCER_NODE_LOAN][field.LOAN_LIMITS][field.LOAN.LOAN_LIMITS__MAX_AMOUNT]
            );

            yield put(loanActions.updateLoanLimitHistory(maxLoanAmount, action.payload.loanAmount));

            if (!_ip) {
                try {
                    _ip = yield getIpAddress();
                } catch (err) {}
            }

            let consentData = yield select(state => ({
                documentTypes: utility.getConsentDocumentsListOnAccountCreation(
                    state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.PERSONAL_INFO][field.DASHBOARD.STATE]
                ),
                mapDocTypeAndName: utility.getConsentDocumentsListWithNamesOnAccountCreation(state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT_KEYS][
                        field.ORGANIZATION.ORG_CONTENT_KEY_STATE
                    ]),
                eConsent: true,
                haveExistingFlexLoan: false,
                userName: state[field.REDUCER_NODE_AUTH][field.USERAUTH][field.EMAIL],
                organizationId:
                    state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][
                        field.ORGANIZATION.ORGANIZATION_ID
                    ],
                state:
                    state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT_KEYS][
                        field.ORGANIZATION.ORG_CONTENT_KEY_STATE
                    ]
            }));

            const consentResponse = yield call(webapi, {
                method: "post",
                url: route.WEB_API_SAVE_CONSENT_CONTENT,
                headers: {
                    "User-Ip": _ip
                },
                data: consentData
            });

            yield put(errorActions.clearApiError(null, field.ERROR_SAVE_CONSENT_DOCUMENT));
            yield put(getConsentContentSuccess(consentResponse));
            yield put(authActions.getLandingPage(data.userId, route.DASHBOARD_RELOAN_PAGE));
        } else {
            yield put(push(route.APPLICATION_REJECT_PAGE));
        }
        yield put(trackingActions.submitDashboardReloanPage());
        yield put(errorActions.clearApiError(null, field.ERROR_RELOANHBCHECK));
        yield put(stopSubmit(form.RELOAN));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_RELOANHBCHECK));
    }
}

export function* isCustomerTXEpicImport(action) {
    try {
        let isCustomerTXEpicImport = route.JAG_API_IS_TX_IMPORT.replace(
            /:customerId/i,
            action.payload.customerId
        ).replace(/:baseLoanId/i, action.payload.baseLoanId);

        const response = yield call(jagapi, {
            method: "get",
            url: isCustomerTXEpicImport
        });
        yield put(errorActions.clearApiError(null, field.ERROR_IS_CUSTOMER_TX_IMPORT));
        yield put(actions.isCustomerTXEpicImportSuccess(response));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_IS_CUSTOMER_TX_IMPORT)
        );
    }
}

export function* updateContactInfo(action) {
    try {
        let customerId = yield select(state => state[field.REDUCER_NODE_AUTH][field.CUSTOMER_ID]);
        let verifyPhoneURL = route.JAG_API_CUSTOMER_UPDATE_CONTACT_INFO.replace(/:customerId/i, customerId);

        let payload = {
            contactNumber: action.payload.contactNumber,
            isMobile: action.payload.isMobile,
            receiveOffers: action.payload.receiveOffers,
            organizationId: utility.getOrganizationId()
        };

        yield jagapi({
            method: "post",
            url: verifyPhoneURL,
            data: payload
        });

        yield put(actions.updateCommPreference(action.payload));

        yield put(errorActions.clearApiError(null, field.ERROR_START_VERIFYPHONE));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_START_VERIFYPHONE));
    }
}

export function* isCardPaymentEnabled(action) {
    try {
        let orgId = yield select(
            state =>
                state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_ID]
        );

        let isCardPaymentEnabledURL = route.CARD_PAYMENT_ENABLED_URL.replace(/:orgId/i, orgId).replace(
            /:statecode/i,
            action.payload.state
        );

        let isCardPaymentEnabled = yield jagapi({
            method: "get",
            url: isCardPaymentEnabledURL
        });

        yield put(actions.isCardPaymentEnabledSuccess(isCardPaymentEnabled));
        yield put(errorActions.clearApiError(null, field.ERROR_IS_CARD_PAYMENT_ENABLED));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_IS_CARD_PAYMENT_ENABLED)
        );
    }
}

export function* getCurrentStatementBalance(action) {
    try {
        let loanType = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_TYPE]
        );

        if (loanType !== LoanType.LINE_OF_CREDIT) {
            return;
        }

        let baseLoanId = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.BASE_LOAN_ID]
        );
        if (baseLoanId) {
            window.localStorage.setItem("baseLoanId", baseLoanId);
        }
        let loanId = window.localStorage.getItem("baseLoanId");
        let statementBalanceUrl = route.GET_CURRENT_STATEMENT_BALANCE_URL.replace(/:baseLoanId/i, loanId);

        let response = yield loanapi({
            method: "get",
            url: statementBalanceUrl
        });

        yield put(actions.getStatementBalanceSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_CURRENT_STATEMENT_BALANCE));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_CURRENT_STATEMENT_BALANCE)
        );
    }
}

export function* getCardPaymentPreview(action) {
    yield put(actions.resetPaymentUrl());
    try {
        let baseLoanId = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.BASE_LOAN_ID]
        );
        let entityId = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.LOAN_LMS_ENTITY_ID]
        );
        let paymentAmount = yield select(
            state =>
                state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.ONLINE_PAYMENT_DETAILS][
                    field.DASHBOARD.ONLINE_PAYMENT_AMOUNT
                ]
        );

        let paymentPreviewUrl = route.CARD_PAYMENT_PREVIEW_URL.replace(/:baseLoanId/i, baseLoanId).replace(
            /:amount/i,
            paymentAmount
        );

        let response = yield loanapi({
            method: "get",
            url: paymentPreviewUrl
        });
        yield put(actions.getCardPaymentPreviewSuccess(response));

        if (utility.isRepayPaymentSupported(entityId)) {
            yield put(actions.getPaymentUrl(baseLoanId, paymentAmount));
        }

        let isLppSupported = yield select(
            state => state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.PAYMENT_ENABLED][field.DASHBOARD.IS_LPP_IFRAME_SUPPORTED]
        )
        if(isLppSupported){
            yield  put(actions.getLppPaymentUrl(baseLoanId, paymentAmount));
        }

        yield put(errorActions.clearApiError(null, field.ERROR_CARD_PAYMENT_PREVIEW));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_CARD_PAYMENT_PREVIEW));
    }
}

export function* trackCardPaymentPreview(action) {
    try {
        let baseLoanId = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.BASE_LOAN_ID]
        );
        let logId = yield select(
            state => action.payload.isLppSupported ?
                state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.PAYMENT_LPP]["logId"] :
                state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.PAYMENT_REPAY]["logId"]
        );

        let cardPaymentPreview = yield select(
            state => state[field.REDUCER_NODE_DASHBOARD][field.DASHBOARD.CARD_PAYMENT_PREVIEW]
        );
        let url = action.payload.isLppSupported ?
            route.LPP_TRACK_CARD_PAYMENT_PREVIEW
                .replace(/:loanId/i, baseLoanId)
                .replace(/:logId/i, logId) :
            route.REPAY_TRACK_CARD_PAYMENT_PREVIEW
            .replace(/:loanId/i, baseLoanId)
            .replace(/:logId/i, logId);

        let data = {
            principal: cardPaymentPreview.principal,
            interest: cardPaymentPreview.interest,
            fees: cardPaymentPreview.fees,
            pastDue: cardPaymentPreview.pastDue,
            outstandingBalance: cardPaymentPreview.outstandingBalance
        }
        yield webapi({
            method: "post",
            url,
            data
        });

        yield put(errorActions.clearApiError(null, field.ERROR_ONE_TIME_PAYMENT_URL));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_ONE_TIME_PAYMENT_URL));
    }
}

export function* submitPayment(action) {
    try {
        let baseLoanId = yield select(
            state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN][field.LOAN.BASE_LOAN_ID]
        );
        yield put(actions.setShowLoader());
        let submitPaymentUrl = route.CARD_PAYMENT_URL.replace(/:baseLoanId/i, baseLoanId);

        let response = yield loanapi({
            method: "post",
            url: submitPaymentUrl,
            data: action.payload
        });
        yield put(actions.unsetShowLoader());
        if (
            response.data != null &&
            ((response.data.cardTransaction !== null && response.data.cardTransaction.success === true) ||
                (response.data.transactions !== null && response.data.transactions[0].cardTransaction.success === true))
        ) {
            yield put(actions.submitCardPaymentSuccess(response));
            yield put(push(route.ONLINE_PAYMENT_CONFIRMATION_PAGE));
        } else {
            yield put(actions.setShowError());
            //yield put(push(route.ONLINE_PAYMENT_ERROR_PAGE));
        }
        yield put(errorActions.clearApiError(null, field.ERROR_SUBMIT_PAYMENT));
    } catch (error) {
        yield put(actions.setShowError());
        yield put(actions.unsetShowLoader());
    }
}

export function* getPaymentUrl(action) {
    try {
        let url = route.REPAY_PAYMENT_URL
            .replace(/:loanId/i, action.payload.loanId)
            .replace(/:amount/i, action.payload.amount);

        let response = yield webapi({
            method: "get",
            url
        });
        yield put(actions.getPaymentUrlSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_ONE_TIME_PAYMENT_URL));
        yield put(trackingActions.repayOneTimePaymentUrlGenerated());
        yield put(actions.trackCardPaymentPreview(response.data, false));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_ONE_TIME_PAYMENT_URL));
        yield put(trackingActions.repayOneTimeUrlError());
    }
}

export function* saveSpousalInfo(action) {
    yield put(startSubmit(form.SPOUSAL_INFO_FORM));
    let baseLoanId = yield select(
        state => state[field.REDUCER_NODE_LOAN][field.LOAN.ACTIVE_LOAN_SUMMARY][field.LOAN.BASE_LOAN_ID]
    );
    let saveSpouseInfoUrl = route.JAG_API_SAVE_SPOUSE_INFO.replace(/:baseLoanId/i, baseLoanId);

    try {
        const response = yield call(jagapi, {
            method: "post",
            url: saveSpouseInfoUrl,
            data: action.payload.data
        });

        yield put(errorActions.clearApiError(null, field.ERROR_SUBMIT_SPOUSE_INFO));
        yield put(actions.saveSpousalInfoSuccess(response));
        yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage("Spouse Info Saved"));
        yield put(trackingActions.submitSpouseInfoItem());
        yield put(actions.getActionItems(baseLoanId));
        try {
            window.scroll(0, 0);
        } catch (e) {
            console.log("Scrolling to top is not working for this browser.");
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SUBMIT_SPOUSE_INFO));
    }
    yield put(stopSubmit(form.SPOUSAL_INFO_FORM));
}

// TDC
export function* getTDCPersonalInformation(action) {
    try {
        let getTDCProfileUrl = route.TDC_API_USER_GET_PROFILE.replace(/:id/i, action.payload.id);
        const response = yield call(api, {
            method: "get",
            url: getTDCProfileUrl
        });

        yield put(actions.getTDCPersonalInformationSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

export function* getTDCRedirectPage(action) {
    try {
        let getTDCRedirectPageURL = route.TDC_API_REDIRECT_PAGE.replace(/:id/i, action.payload.id);
        const response = yield call(api, {
            method: "get",
            url: getTDCRedirectPageURL
        });

        yield put(actions.getTDCRedirectPageSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_TDC_REDIRECT_PAGE));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_TDC_REDIRECT_PAGE));
    }
}

export function* submitForAutoApproval(action) {
    try {
        yield put(trackingActions.trackSubmitForAutoApproval());
        let submitForAutoApprovalURL = route.LOAN_SUBMIT_FOR_AUTO_APPROVAL.replace(
            /:baseLoanId/i,
            action.payload.baseLoanId
        );
        const response = yield call(api, {
            method: "post",
            url: submitForAutoApprovalURL
        });

        yield put(loanActions.getLatestNonWithdrawnLoanSummary(action.payload.customerId));
        yield put(loanActions.getActiveLoan(action.payload.customerId));
        yield put(actions.submitForAutoApprovalSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_SUBMIT_AUTO_APPROVAL));
        yield put(trackingActions.trackSubmitForAutoApprovalSuccess());
    } catch (error) {
        yield put(trackingActions.trackSubmitForAutoApprovalError());
        // don't display error on the screen
        //yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SUBMIT_AUTO_APPROVAL));
    }
}

export function* otpGetStatus(action) {
    try {
        let url = route.JAG_API_CUSTOMER_OTP_STATUS.replace(/:customerId/i, action.payload.customerId);
        let response = yield jagapi({
            method: "get",
            url
        });
        yield put(actions.otpGetStatusSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_OTP_GET_STATUS));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_GET_STATUS));
    }
}

export function* otpSendCode(action) {
    try {
        if (action.payload.isSendCodeAllowed) {
            let url = route.JAG_API_CUSTOMER_OTP_SEND_CODE.replace(/:customerId/i, action.payload.customerId);
            let response = yield jagapi({
                method: "post",
                url,
                data: {
                    shouldSendOnFrequentRepeatRequest: false
                }
            });

            if (response.data.errorCode === "FREQUENT_REPEAT_REQUEST") {
                yield put(
                    createAndTrackToastMessageAction.createAndTrackErrorMessage(
                        utility.getAPIErrorPayload(errorMessages.OTP_VERIFICATION_CODE_ALREADY_SENT),
                        null
                    )
                );
                yield put(trackingActions.otpSendCodeFailed());
            } else {
                yield put(actions.otpSendCodeSuccess(response));
                yield put(trackingActions.otpSendCodeSuccess());
                yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage("Verification code sent"));
                yield put(errorActions.clearApiError(null, field.ERROR_OTP_SEND_CODE));
            }
        } else {
            yield put(
                createAndTrackToastMessageAction.createAndTrackErrorMessage(
                    utility.getAPIErrorPayload(errorMessages.OTP_VERIFICATION_CODE_ALREADY_SENT),
                    null
                )
            );
            yield put(trackingActions.otpSendCodeFailed());
        }
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_SEND_CODE));
    }
}

export function* otpVerifyCode(action) {
    try {
        yield put(startAsyncValidation(action.payload.form));
        let url = route.JAG_API_CUSTOMER_OTP_VERIFY.replace(/:customerId/i, action.payload.customerId);
        let response = yield jagapi({
            method: "post",
            url,
            data: {
                verificationCode: action.payload.verificationCode
            }
        });
        switch (response.data.errorCode) {
            case "EXPIRED_OTP":
                yield put(
                    stopAsyncValidation(action.payload.form, {
                        [action.payload.field]: errorMessages.OTP_VERIFICATION_CODE_INVALID_CODE_DASHBOARD
                    })
                );
                break;
            case "INVALID_OTP":
                yield put(
                    stopAsyncValidation(action.payload.form, {
                        [action.payload.field]: errorMessages.OTP_VERIFICATION_CODE_INVALID_CODE_DASHBOARD
                    })
                );
                break;
            case "ONE_TIME_PASSWORD_DISABLED":
                yield put(stopAsyncValidation(action.payload.form, {}));
                break;
            case "MAX_LIMIT_REACHED":
                yield put(stopAsyncValidation(action.payload.form, {}));
                break;
            default:
                yield put(stopAsyncValidation(action.payload.form, {}));
                break;
        }
        yield put(actions.otpVerifyCodeSuccess(response));
        yield put(trackingActions.otpVerifyCode(response.data.errorCode, response.data.otpStatus));
        yield put(errorActions.clearApiError(null, field.ERROR_OTP_VERIFY_CODE));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_VERIFY_CODE));
    }
}

export function* otpProcessData(action) {
    try {
        let url = route.HOTBOX_OTP_PROCESSDATA.replace(/:baseLoanId/i, action.payload.baseLoanId);
        let response = yield call(hotboxapi, {
            method: "post",
            url: url,
            data: {
                lead_sequence_id: action.payload.lead_sequence_id,
                type: action.payload.type,
                phoneNumber: action.payload.phoneNumber,
                email: action.payload.email,
                organizationId: action.payload.organizationId
            }
        });
        yield put(actions.processOTPDataSuccess(response));
        yield put(trackingActions.otpProcessDataSuccess());
        let customerId = yield select(state => state[field.REDUCER_NODE_AUTH][field.CUSTOMER_ID]);
        yield put(loanActions.getActiveLoan(customerId));
        yield put(errorActions.clearApiError(null, field.ERROR_OTP_PROCESS_DATA));
    } catch (error) {
        yield put(trackingActions.otpProcessDataFailed());
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_OTP_PROCESS_DATA));
    }
}

export function* payrollProcessData(action) {
    try {
        let url = route.HOTBOX_PAYROLL_PROCESSDATA.replace(/:baseLoanId/i, action.payload.baseLoanId);
        yield put(actions.setShowLoader());
        let response = yield call(hotboxapi, {
            method: "post",
            url: url,
            data: {
                lead_sequence_id: action.payload.lead_sequence_id,
                organizationId: action.payload.organizationId
            }
        });
        yield put(actions.processPayrollDataSuccess(response));
        yield put(trackingActions.payrollProcessDataSuccess());
        let customerId = yield select(state => state[field.REDUCER_NODE_AUTH][field.CUSTOMER_ID]);
        yield put(loanActions.getActiveLoan(customerId));
        yield put(errorActions.clearApiError(null, field.ERROR_PAYROLL_PROCESS_DATA));
        yield put(actions.unsetShowLoader());
    } catch (error) {
        yield put(trackingActions.payrollProcessDataFailed());
        yield put(actions.unsetShowLoader());
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_PAYROLL_PROCESS_DATA));
    }
}

export function* checkDrawEligibility(action) {
    try {
        let url = route.LOAN_CHECK_DRAW_ELIGIBILITY.replace(/:baseLoanId/i, action.payload.baseLoanId);
        let response = yield call(loanapi, {
            method: "get",
            url: url
        });

        yield put(actions.checkDrawEligibilitySuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_CHECK_DRAW_ELIGIBILITY));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_CHECK_DRAW_ELIGIBILITY)
        );
    }
}

export function* showLocLowerFee(action) {
    try {
        let url = route.LOAN_SHOW_LOC_LOWER_FEE.replace(/:baseLoanId/i, action.payload.baseLoanId);
        let response = yield call(loanapi, {
            method: "get",
            url: url
        });

        yield put(actions.showLocLowerFeeSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_SHOW_LOC_LOWER_FEE));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_SHOW_LOC_LOWER_FEE));
    }
}

export function* saveLocLowerFeePopupAcknowledgement(action) {
    try {
        let url = route.LOAN_SAVE_LOC_LOWER_FEE_POPUP_ACKNOWLEDGEMENT.replace(
            /:baseLoanId/i,
            action.payload.baseLoanId
        );
        let response = yield call(loanapi, {
            method: "get",
            url: url
        });

        yield put(actions.saveLocLowerFeePopupAcknowledgementSuccess(response));
        yield call(showLocLowerFee, action);
        yield put(errorActions.clearApiError(null, field.ERROR_SAVE_LOC_LOWER_FEE_POPUP_ACKNOWLEDGEMENT));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(
                error,
                field.ERROR_SAVE_LOC_LOWER_FEE_POPUP_ACKNOWLEDGEMENT
            )
        );
    }
}

export function* saveDocumentViewedOnDashboard(action) {
    try {
        let url = route.LOAN_SAVE_DOCUMENT_VIEWED_ON_DASHBOARD;

        let payload = {
            userId: action.payload.userId,
            baseLoanId: action.payload.baseLoanId,
            documentVersionId: action.payload.documentVersionId
        };

        let response = yield call(loanapi, {
            method: "post",
            url: url,
            data: payload
        });

        yield put(actions.setDocumentViewedSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_SAVE_DOCUMENT_VIEWED_ON_DASHBOARD));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(
                error,
                field.ERROR_SAVE_DOCUMENT_VIEWED_ON_DASHBOARD
            )
        );
    }
}

export function* documentUpload(action) {
    yield put(startSubmit(form.DOCUMENT_UPLOAD_FORM));
    let response;
    try {
        let url = route.LOAN_API_DOCUMENT_UPLOAD.replace(/:baseLoanId/i, action.payload.baseLoanId);

        let payload = {
            documentType: action.payload.data.documentType,
            documentDescription: action.payload.data.documentDescription,
            file: action.payload.data.file
        };

        let formData = new FormData();
        formData.append("documentType", payload.documentType);
        formData.append("documentDescription", payload.documentDescription);
        formData.append("file", payload.file);

        response = yield call(api, {
            method: "post",
            url: url,
            data: formData
        });

        yield put(trackingActions.submitDocumentUploadPage());
        yield put(errorActions.clearApiError(null, field.ERROR_DOCUMENT_UPLOAD));
        yield put(errorActions.clearApiError(null, field.ERROR_DOCUMENT_UPLOAD_VIRUS));
        yield put(actions.documentUploadSuccess(response));
    } catch (error) {
        if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status === apiErrorCodes.API_ERROR_CODE.ERROR_406
        ) {
            yield put(errorActions.clearApiError(null, field.ERROR_DOCUMENT_UPLOAD));
            yield put(errorActions.clearApiError(null, field.ERROR_DOCUMENT_UPLOAD_VIRUS));
            if (utility.getResponseErrorCode(error) === API_ERROR_MESSAGE.API_ERROR_VIRUS_DETECTED) {
                yield put(
                    createAndTrackToastMessageAction.createAndTrackErrorMessage(
                        error,
                        field.ERROR_DOCUMENT_UPLOAD_VIRUS
                    )
                );
            } else {
                yield put(
                    createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_DOCUMENT_UPLOAD)
                );
            }
        } else {
            if (error.message === API_ERROR_MESSAGE.API_ERROR_TIMEOUT) {
                const errorObj = utility.getErrorObjForTimeout(error);
                yield put(
                    createAndTrackToastMessageAction.createAndTrackErrorMessage(errorObj, field.ERROR_DOCUMENT_UPLOAD)
                );
            } else {
                yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
            }
        }
    }
    yield put(stopSubmit(form.DOCUMENT_UPLOAD_FORM));
}

export function* documentUploadSuccess(action) {
    try {
        yield put(
            push({
                pathname: route.DASHBOARD_DOCUMENT_UPLOAD_CONFIRMATION,
                state: {confirmationResponse: action.payload.result.data.data}
            })
        );
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* isDocumentUploadEnabled(action) {
    try {
        let orgId = yield select(
            state =>
                state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_ID]
        );

        let isDocumentUploadEnabledURL = route.DOC_UPLOAD_ENABLED_URL.replace(/:orgId/i, orgId).replace(
            /:statecode/i,
            action.payload.state
        );

        let isDocumentUploadEnabled = yield jagapi({
            method: "get",
            url: isDocumentUploadEnabledURL
        });
        yield put(actions.isDocumentUploadEnabledSuccess(isDocumentUploadEnabled));
        yield put(errorActions.clearApiError(null, field.ERROR_IS_DOCUMENT_UPLOAD_ENABLED));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_IS_DOCUMENT_UPLOAD_ENABLED)
        );
    }
}

export function* isAccountOnHold(action) {
    try {
        let getProfileUrl = route.WEB_API_IS_ACCOUNT_ON_HOLD.replace(/:customerId/i, action.payload.id);
        const response = yield call(jagapi,{
            method: "get",
            url: getProfileUrl
        });
        yield put(actions.isAccountOnHoldSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_IS_ACCOUNT_ON_HOLD));
    } catch (error) {
        yield put(
            createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_IS_ACCOUNT_ON_HOLD)
        );
    }
}

export function* createOnfidoSdk(action) {
    try {
        let onfidoCreateSdkUrl = route.ONFIDO_CREATE_SDK_URL
        .replace(/:customerId/i, action.payload.customerId)
        .replace(/:baseLoanId/i, action.payload.loanId);

        let response = yield api({
            method: "post",
            url: onfidoCreateSdkUrl
        });

        if (!!response.data.hasDocumentsUploaded) {
            yield put(actions.onfidoSdkEventCallback({
                "baseLoanId": action.payload.loanId,
                "callback": constant.IDENTITY_VERIFICATION_CALLBACK_EVENT_ON_COMPLETE,
                "sdkId": response.data.sdkId
            }))
        }
        yield put(actions.createOnfidoSdkSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_CREATE_ONFIDO_SDK));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_CREATE_ONFIDO_SDK));
    }
}

export function* onfidoSdkEventCallback(action) {
    try {
        let onfidoCreateSdkUrl = route.ONFIDO_SDK_EVENT_CALLBACK_URL
            .replace(/:sdkId/i, action.payload.sdkId);

        let response = yield api({
            method: "post",
            url: onfidoCreateSdkUrl,
            data: {...action.payload}
        });

        yield put(actions.onfidoSdkEventCallbackSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_CREATE_ONFIDO_SDK));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_CREATE_ONFIDO_SDK));
    }
}

export function* onfidoSdkEventTracking(action) {
    try {
        let onfidoSdkEventTrackingUrl = route.ONFIDO_SDK_EVENT_TRACKING_URL
            .replace(/:sdkId/i, action.payload.sdkId)
            .replace(/:baseLoanId/i, action.payload.baseLoanId);

        let response = yield api({
            method: "post",
            url: onfidoSdkEventTrackingUrl,
            data: {...action.payload.data}
        });

        yield put(actions.onfidoSdkEventTrackingSuccess(response));
        yield put(errorActions.clearApiError(null, field.ERROR_CREATE_ONFIDO_SDK_EVENT_TRACKING));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, field.ERROR_CREATE_ONFIDO_SDK_EVENT_TRACKING));
    }
}
export function* cancelDraw(action) {
    yield put(startSubmit(form.CANCEL_DRAW_REQUEST));
    try {
        let cancelDrawUrl = route.LOAN_API_CUSTOMER_CANCEL_DRAW
            .replace(/:baseLoanId/i,action.payload.baseLoanId);
        const response = yield call(api,{
            method: "post",
            url: cancelDrawUrl,
            data: {}
        });
        yield put(actions.cancelDrawSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
    yield put(loanActions.getActiveLoanSummary(action.payload.customerId));
}

export function* getCreditEvolutionStatus(action) {
    try {
        let url = route.WEB_API_GET_CREDIT_EVOLUTION_STATUS
            .replace(/:baseLoanId/i, action.payload.loanId);
        let response = yield call(api,{
            method: "get",
            url
        });
        yield put(actions.getCreditEvolutionStatusSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* getPricingModelDetails(action) {
    try {
        let url = route.WEB_API_GET_PRICING_MODEL_DETAIL
            .replace(/:baseLoanId/i, action.payload.loanId);
        let response = yield call(api,{
            method: "get",
            url
        });
        yield put(actions.getPricingModelDetailsSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* getCreditEvolutionDetail(action) {
    try {
        let url = route.WEB_API_GET_CREDIT_EVOLUTION_DETAIL
            .replace(/:baseLoanId/i, action.payload.loanId);
        let response = yield call(api,{
            method: "get",
            url
        });

        yield put(actions.getCreditEvolutionDetailSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* getAchInfo(action) {
    try {
        let url = route.WEB_API_GET_ACH_INFO
            .replace(/:baseLoanId/i, action.payload.loanId);
        let response = yield call(api,{
            method: "get",
            url
        });
        yield put(actions.getAchInfoSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* getFilledDocument(action) {
    try {
        let url = route.WEB_API_GET_FILLED_DOCUMENT
            .replace(/:baseLoanId/i, action.payload.loanId)
            .replace(/:docGroup/i, action.payload.docGroup);
        let response = yield call(api,{
            method: "get",
            url
        });
        yield put(actions.getFilledDocumentSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* submitSignedDocument(action) {
    try {
        let ipAddress = yield getIpAddress();

        let url = route.WEB_API_SUBMIT_SIGNED_DOCUMENT
            .replace(/:baseLoanId/i, action.payload.loanId)
            .replace(/:docGroup/i, action.payload.docGroup);
        yield call(api, {
            method: "post",
            url: url,
            data: {...action.payload, ipAddress}
        });

        yield put(push(route.DASHBOARD_VIEW_PROFILE_PAGE));
        yield put(createAndTrackToastMessageAction.createAndTrackSuccessMessage(utility.isForaCredit() ? toastMessages.SIGNED_STANDALONE_ACH_SUCCESS_CAN : toastMessages.SIGNED_STANDALONE_ACH_SUCCESS_US));
        yield put(errorActions.clearApiError(null, field.ERROR_CREATE_ONFIDO_SDK_EVENT_TRACKING));
        yield put(actions.getAchInfo(action.payload.loanId));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}

export function* getLppPaymentUrl(action) {
    try {
        let url = route.LPP_PAYMENT_URL
            .replace(/:loanId/i, action.payload.loanId)
            .replace(/:amount/i, action.payload.amount);

        let response = yield webapi({
            method: "get",
            url
        });
        yield put(actions.getLppPaymentUrlSuccess(response));
        yield put(trackingActions.lppOneTimePaymentUrlGenerated());
        yield put(actions.trackCardPaymentPreview(response.data, true));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}
