import * as validators from "../../../../shared/utility/validators";
import * as errorCodes from "../../../../shared/constant/errorCodes";
import * as errorMessages from "../../../../shared/constant/errorMessages";
import * as fieldtags from "../../../../shared/constant/fieldtags";
import * as constant from "../../../../shared/constant/constant";
import {isForaCredit} from "../../../../shared/utility/utility";
import * as utility from "../../../../shared/utility/utility";

function _hasNumber(str) {
    return /\d/.test(str);
}

export function _isNumbersOnly(str) {
    return /^[0-9]+$/.test(str);
}

export function _isCanadaPostalCode(str) {
    return /[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/.test(str);
}

export function _isNumbersAndSpacesOnly(str) {
    return /^[\d\s]+$/.test(str);
}

export function isValidLineAddress1(lineAddress1, errors = {}) {
    if (validators.isTextEmpty(lineAddress1)) {
        errors[fieldtags.ADDRESSINFO_ADDRESS_LINE_1] = errorMessages.ADDRESSINFO_STREET_ADDRESS__EMPTY;
        return errors[fieldtags.ADDRESSINFO_ADDRESS_LINE_1];
    }

    if (_isNumbersAndSpacesOnly(lineAddress1)) {
        errors[fieldtags.ADDRESSINFO_ADDRESS_LINE_1] = errorMessages.ADDRESSINFO_STREET_ADDRESS__HAS_NUMBERS_ONLY;
    }

    if (validators.isStringTooShort(lineAddress1, 5)) {
        errors[fieldtags.ADDRESSINFO_ADDRESS_LINE_1] = errorMessages.ADDRESSINFO_STREET_ADDRESS__INVALID_LENGTH;
    }

    if (isNaN(parseInt(lineAddress1.trim().charAt(0)))) {
        errors[fieldtags.ADDRESSINFO_ADDRESS_LINE_1] =
            errorMessages.ADDRESSINFO_STREET_ADDRESS__DOES_NOT_START_WITH_NUMBER;
    }

    if (utility.getOrganizationId() === constant.CREDITFRESH_ORGANIZATION_ID
        || utility.getOrganizationId() === constant.CREDITFRESH_PLUS_ORGANIZATION_ID) {
        if (validators.isPOBox(lineAddress1)) {
            errors[fieldtags.ADDRESSINFO_ADDRESS_LINE_1] =
                errorMessages.ADDRESSINFO_PO_BOX;
        }
    }

    return true;
}

export function isValidStreetAddress(streetAddress, errors = {}) {
    if (validators.isTextEmpty(streetAddress)) {
        errors[fieldtags.STREET_ADDRESS] = errorMessages.ADDRESSINFO_STREET_ADDRESS__EMPTY;
        return errors[fieldtags.STREET_ADDRESS];
    }

    if (_isNumbersAndSpacesOnly(streetAddress)) {
        errors[fieldtags.STREET_ADDRESS] = errorMessages.ADDRESSINFO_STREET_ADDRESS__HAS_NUMBERS_ONLY;
    }

    if (validators.isStringTooShort(streetAddress, 5)) {
        errors[fieldtags.STREET_ADDRESS] = errorMessages.ADDRESSINFO_STREET_ADDRESS__INVALID_LENGTH;
    }

    if (isNaN(parseInt(streetAddress.trim().charAt(0)))) {
        errors[fieldtags.STREET_ADDRESS] =
            errorMessages.ADDRESSINFO_STREET_ADDRESS__DOES_NOT_START_WITH_NUMBER;
    }

    if(validators.isPOBox(streetAddress)){
        errors[fieldtags.STREET_ADDRESS] = errorMessages.ADDRESSINFO_PO_BOX;
    }

    return true;
}


export function isValidLineAddress2(unit, errors = {}) {
    if (validators.isSpecialCharactersOnly(unit)) {
        errors[fieldtags.ADDRESSINFO_ADDRESS_LINE_2] = errorMessages.ADDRESSINFO_UNIT__SPECIAL_CHARACTERS;
        return errors[fieldtags.ADDRESSINFO_ADDRESS_LINE_2];
    }

    return true;
}

export function isValidCity(city, errors = {}) {
    if (validators.isTextEmpty(city)) {
        errors[fieldtags.ADDRESSINFO_CITY] = errorMessages.ADDRESSINFO_CITY__EMPTY;
        return errors[fieldtags.ADDRESSINFO_CITY];
    }

    if (_hasNumber(city)) {
        errors[fieldtags.ADDRESSINFO_CITY] = errorMessages.ADDRESSINFO_CITY__HAS_NUMBERS;
        return errors[fieldtags.ADDRESSINFO_CITY];
    }

    if (validators.isSpecialCharactersOnly(city)) {
        errors[fieldtags.ADDRESSINFO_CITY] = errorMessages.ADDRESSINFO_CITY__SPECIAL_CHARACTERS;
        return errors[fieldtags.ADDRESSINFO_CITY];
    }

    return true;
}

export function isValidState(state, errors = {}) {
    switch (validators.isValidState(state)) {
        case errorCodes.STATE__EMPTY:
            errors[fieldtags.ADDRESSINFO_STATE] = errorMessages.STATE__EMPTY;
            return errors[fieldtags.ADDRESSINFO_STATE];

        case errorCodes.STATE__INVALID:
            errors[fieldtags.ADDRESSINFO_STATE] = errorMessages.STATE__INVALID;
            return errors[fieldtags.ADDRESSINFO_STATE];

        default:
            return true;
    }
}

export function isValidProvince(state, errors = {}) {
    switch (validators.isValidProvince(state)) {
        case errorCodes.STATE__EMPTY:
            errors[fieldtags.ADDRESSINFO_STATE] = errorMessages.STATE__EMPTY_CANADA;
            return errors[fieldtags.ADDRESSINFO_STATE];

        case errorCodes.STATE__INVALID:
            errors[fieldtags.ADDRESSINFO_STATE] = errorMessages.STATE__EMPTY_CANADA;
            return errors[fieldtags.ADDRESSINFO_STATE];

        default:
            return true;
    }
}

export function isValidZipcode(zipcode, errors = {}) {
    if (validators.isTextEmpty(zipcode)) {
        errors[fieldtags.ADDRESSINFO_ZIPCODE] = errorMessages.ADDRESSINFO_ZIPCODE__EMPTY;
        return errors[fieldtags.ADDRESSINFO_ZIPCODE];
    }

    if (!_isNumbersOnly(zipcode)) {
        errors[fieldtags.ADDRESSINFO_ZIPCODE] = errorMessages.ADDRESSINFO_ZIPCODE__INVALID_CHARACTERS;
        return errors[fieldtags.ADDRESSINFO_ZIPCODE];
    }

    if (validators.hasOnlyZeros(zipcode)) {
        errors[fieldtags.ADDRESSINFO_ZIPCODE] = errorMessages.ADDRESSINFO_ZIPCODE__ALLZEROS;
        return errors[fieldtags.ADDRESSINFO_ZIPCODE];
    }

    if (zipcode.length !== 5) {
        errors[fieldtags.ADDRESSINFO_ZIPCODE] = errorMessages.ADDRESSINFO_ZIPCODE__INVALID_LENGTH;
        return errors[fieldtags.ADDRESSINFO_ZIPCODE];
    }
}

export function isValidPostalCode(postalCode, errors = {}) {
    if (validators.isTextEmpty(postalCode)) {
        errors[fieldtags.ADDRESSINFO_POSTAL_CODE] = errorMessages.ADDRESSINFO_POSTAL_CODE__EMPTY;
        return errors[fieldtags.ADDRESSINFO_POSTAL_CODE];
    }

    if (!_isCanadaPostalCode(postalCode)) {
        errors[fieldtags.ADDRESSINFO_POSTAL_CODE] = errorMessages.ADDRESSINFO_POSTAL_CODE__INVALID_CHARACTERS;
        return errors[fieldtags.ADDRESSINFO_POSTAL_CODE];
    }

    if (postalCode.length < 7 && postalCode >6) {
        errors[fieldtags.ADDRESSINFO_POSTAL_CODE] = errorMessages.ADDRESSINFO_POSTAL_CODE__INVALID_LENGTH;
        return errors[fieldtags.ADDRESSINFO_POSTAL_CODE];
    }
}

export function isValidResidencyStatus(residencyStatus, errors = {}) {
    switch (validators.isValidResidencyStatus(residencyStatus)) {
        case errorCodes.RESIDENCY__EMPTY:
            errors[fieldtags.ADDRESSINFO_RESIDENCY_STATUS] = errorMessages.RESIDENCY__EMPTY;
            return errors[fieldtags.ADDRESSINFO_RESIDENCY_STATUS];

        default:
            return true;
    }
}

export default function validate(values) {
    let errors = {};
 if(isForaCredit())
 {
     isValidStreetAddress(values[fieldtags.STREET_ADDRESS], errors);
     isValidPostalCode(values[fieldtags.ADDRESSINFO_POSTAL_CODE], errors);
     isValidProvince(values[fieldtags.ADDRESSINFO_STATE], errors);
 }
 else{
     isValidLineAddress1(values[fieldtags.ADDRESSINFO_ADDRESS_LINE_1], errors);
     isValidState(values[fieldtags.ADDRESSINFO_STATE], errors);
     isValidZipcode(values[fieldtags.ADDRESSINFO_ZIPCODE], errors);
 }
    isValidLineAddress2(values[fieldtags.ADDRESSINFO_ADDRESS_LINE_2], errors);
    isValidCity(values[fieldtags.ADDRESSINFO_CITY], errors);


    return errors;
}
