import _ from "lodash";
import moment from "moment";

import {STATE_CODES} from "../constant/stateCodes";
import * as constant from "../constant/constant";
import * as errorCodes from "../constant/errorCodes";
import * as utility from "./utility";
import * as _helpers from "../helpers";
import {isForaCredit} from "./utility";

const TYPE_STRING = "TYPE_STRING";
const TYPE_NUMBER = "TYPE_NUMBER";

function _expectString(str) {
    if (!_.isString(str)) {
        throw new Error("expected a string");
    }
}

function _expectNumber(num) {
    if (!_.isNumber(num)) {
        throw new Error("expected a number");
    }
}

function _expect(type, value, strict = false) {
    if (!(strict || value)) {
        return;
    }

    if(type === TYPE_STRING) {
        return _expectString(value);
    }

    if(type === TYPE_NUMBER) {
        return _expectNumber(value);
    }

    if (process.env.NODE_ENV === "development") {
        throw new Error("expected type not recognized");
    }
}

export function isStringEmpty(str) {
    _expect(TYPE_STRING, str, true);
    return _.trim(str) === "";
}

export function isTextEmpty(str) {
    return !str || isStringEmpty(str);
}

export function isStringTooShort(str, num) {
    return _.trim(str.replace(/\s+/g, " ")).length < num;
}

export function isStringlengthInValid(str, minlength, maxlength) {
    str = str.trim(str.replace(/\s+/g, " "));
    return str.length < minlength || str.length > maxlength;
}

export function isLengthGreaterThan(str, length = 7) {
    return str.length > length;
}

export function hasLowercaseLetter(str) {
    return /[a-z]/.test(str);
}

export function hasUppercaseLetter(str) {
    return /[A-Z]/.test(str);
}

export function hasLetter(str) {
    return /[A-Za-z]/.test(str);
}

export function hasNumber(str) {
    return /\d/.test(str);
}

export function hasOnlyZeros(str) {
    return !/[1-9]+/.test(str);
}

export function isCheckSumOk(str) {
    const routingNumberArray = str.split("");
    const checksumMultipliers = [3, 7, 1, 3, 7, 1, 3, 7, 1];
    let sum = 0;
    routingNumberArray.map((item, index) => sum = sum + (checksumMultipliers[index] * item));

    return sum % 10 === 0;
}

export function hasSpecialCharacter(str) {
    return /[!@#$%^&*()_+\-=[\]{};~`':"/\\|,.<>?]/.test(str);
}

export function isSpecialCharactersOnly(str) {
    return /^\W+$/.test(str);
}

export function isValidEmailAddress(emailAddress) {
    _expect(TYPE_STRING, emailAddress);

    if (isTextEmpty(emailAddress)) {
        return errorCodes.EMAIL_ADDRESS__EMPTY;
    }

    if (
        !/^[a-z0-9._+-]{1,64}@[a-z0-9][a-z0-9.-]*\.[a-z]{2,}$/i.test(emailAddress) ||
        emailAddress.charAt(0) === "." ||
        emailAddress.indexOf("..") > 0 ||
        emailAddress.indexOf(".@") > 0
    ) {
        return errorCodes.EMAIL_ADDRESS__INVALID;
    }

    return true;
}

export function isValidState(state) {
    _expect(TYPE_STRING, state);
    if (isTextEmpty(state)) {
        return errorCodes.STATE__EMPTY;
    }
    if(_helpers.getStateFromStateCode(state.toUpperCase())!=null && !(utility.isMoneykey() || utility.isPathward())){
        state=_helpers.getStateFromStateCode(state.toUpperCase());
    }
    if (!_.includes(STATE_CODES,(_.startCase(_.toLower(state))))) {
        return errorCodes.STATE__INVALID;
    }
    return true;
}

export function isValidProvince(province) {
    _expect(TYPE_STRING, province);
    if (isTextEmpty(province)) {
        return errorCodes.STATE__EMPTY;
    }

    if (!_.includes(STATE_CODES, _.startCase(_.toLower(province)))) {
        return errorCodes.STATE__INVALID;
    }

    return true;
}

export function isValidResidencyStatus(status) {
    _expect(TYPE_STRING, status);

    if (isTextEmpty(status)) {
        return errorCodes.RESIDENCY__EMPTY;
    }

    return true;
}

export function isValidSpouseState(state) {
    _expect(TYPE_STRING, state);

    if (isTextEmpty(state)) {
        return errorCodes.SPOUSE_STATE__EMPTY;
    }

    if (!_.includes(STATE_CODES, _.startCase(_.toLower(state)))) {
        return errorCodes.SPOUSE_STATE__INVALID;
    }

    return true;
}

export function isValidCalendarMonth(num) {
    _expect(TYPE_NUMBER, num, true);
    return num >= 1 && num < 13;
}

export function isValidCalendarDay(num) {
    _expect(TYPE_NUMBER, num, true);
    return num >= 1 && num < 32;
}

export function isValidCalendarYear(num) {
    _expect(TYPE_NUMBER, num, true);
    return num >= 0;
}

export function isValidFirstName(firstName) {
    _expect(TYPE_STRING, firstName);

    if (isTextEmpty(firstName)) {
        return errorCodes.FIRST_NAME__EMPTY;
    }

    if (isStringTooShort(firstName, 2)) {
        return errorCodes.FIRST_NAME__LESS_THAN_2_CHARS;
    }

    return true;
}

export function isValidLastName(lastName) {
    _expect(TYPE_STRING, lastName);

    if (isTextEmpty(lastName)) {
        return errorCodes.LAST_NAME__EMPTY;
    }

    if (isStringTooShort(lastName, 2)) {
        return errorCodes.LAST_NAME__LESS_THAN_2_CHARS;
    }

    return true;
}

/**
 * isValidBirthdate
 * @param {string} birthdate
 * @param {string} state - state code ( optional )
 * @param {string} format - format of birthdate
 */
export function isValidBirthdate(birthdate, state, format = "MM/DD/YYYY") {
    _expect(TYPE_STRING, birthdate);

    if (isTextEmpty(birthdate)) {
        return errorCodes.BIRTHDATE__EMPTY;
    }

    let match = /(\d\d)?\/?(\d\d)?\/?(\d\d\d\d)?/.exec(birthdate);

    let month = parseInt(match[1], 10);
    if (!isNaN(month) && !isValidCalendarMonth(month)) {
        return errorCodes.BIRTHDATE__MONTH_INVALID;
    }

    let date = parseInt(match[2], 10);
    if (!isNaN(date) && !isValidCalendarDay(date)) {
        return errorCodes.BIRTHDATE__DAY_INVALID;
    }

    let year = parseInt(match[3], 10);
    if (!isNaN(year) && !isValidCalendarYear(year)) {
        return errorCodes.BIRTHDATE__YEAR_INVALID;
    }

    if (isNaN(month) || isNaN(date) || isNaN(year)) {
        return errorCodes.BIRTHDATE__INVALID;
    }

    let _birthdate = moment(birthdate, format);
    if (!_birthdate.isValid()) {
        switch (_birthdate.invalidAt()) {
            case 0:
                return errorCodes.BIRTHDATE__YEAR_INVALID;
            case 1:
                return errorCodes.BIRTHDATE__MONTH_INVALID;
            case 2:
                return errorCodes.BIRTHDATE__DAY_INVALID;
            default:
                return errorCodes.BIRTHDATE__INVALID;
        }
    }

    let today = moment().startOf("day");
    let years = today.diff(_birthdate, "years", true);

    if (years < 0) {
        return errorCodes.BIRTHDATE__IS_IN_THE_FUTURE;
    }

    if (constant.STATE_MIN_AGE_19.includes(state) && years < 19) {
        return errorCodes.BIRTHDATE__LESS_THAN_19_YEARS;
    }

    if (years < 18) {
        return errorCodes.BIRTHDATE__LESS_THAN_18_YEARS;
    }

    if (years > 120) {
        return errorCodes.BIRTHDATE__MORE_THAN_120_YEARS;
    }

    return true;
}

export function isValidSSN(ssn, length = 9) {
    _expect(TYPE_STRING, ssn);

    if (isTextEmpty(ssn)) {
        return errorCodes.SSN__EMPTY;
    }
    if (ssn.replace(/[^0-9 "*"]/g, "").length !== length) {
        return errorCodes.SSN__INVALID;
    }

    return true;
}

export function isValidUnMaskedSSN(ssn, length = 9) {
    _expect(TYPE_STRING, ssn);

    if (isTextEmpty(ssn)) {
        return errorCodes.SSN__EMPTY;
    }
    if (ssn.replace(/[^0-9 "*"]/g, "").length !== length) {
        return errorCodes.SSN__INVALID;
    }

    return true;
}

export function isValidUnMaskedSIN(ssn, length = 9) {
    _expect(TYPE_STRING, ssn);

    if (isTextEmpty(ssn)) {
        return errorCodes.SSN__EMPTY;
    }
    if (ssn.replace(/[^0-9 "*"]/g, "").length !== length) {
        return errorCodes.SSN__INVALID;
    }

    return true;
}

export function isValidSignedSSN(input, lastFourDigitsOfSSN) {
    _expect(TYPE_STRING, input);
    _expect(TYPE_STRING, lastFourDigitsOfSSN);

    if (isTextEmpty(input)) {
        return errorCodes.SSN_FOUR_LAST_DIGITS__EMPTY;
    }

    if (input.replace(/[^0-9]/g, "").length !== 4) {
        return errorCodes.SSN_FOUR_LAST_DIGITS__LESS_THAN_4_CHARS;
    }

    if (input !== lastFourDigitsOfSSN) {
        return errorCodes.SSN_FOUR_LAST_DIGITS__NO_MATCH;
    }

    return true;
}

export function isValidInitials(input, firstName, lastName) {
    _expect(TYPE_STRING, input);
    _expect(TYPE_STRING, firstName);
    _expect(TYPE_STRING, lastName);

    let initials = firstName.substring(0, 1) + lastName.substring(0, 1);

    if (isTextEmpty(input)) {
        return errorCodes.LOAN_DOC_INITIALS__EMPTY;
    }

    if (input.toUpperCase() !== initials.toUpperCase()) {
        return errorCodes.LOAN_DOC_INITIALS__NO_MATCH;
    }

    return true;
}

export function isValidPhoneNumber(phoneNumber, length = 10) {
    _expect(TYPE_STRING, phoneNumber);

    if (isTextEmpty(phoneNumber)) {
        return errorCodes.PHONE_NUMBER__EMPTY;
    }

    if (phoneNumber.replace(/[^0-9]/g, "").length !== length) {
        return errorCodes.PHONE_NUMBER__INVALID;
    }

    return true;
}

export function isValidApplicationCode(appcode) {
    _expect(TYPE_STRING, appcode);

    if (isTextEmpty(appcode)) {
        return errorCodes.APPCODE__EMPTY;
    }

    return true;
}

export function isValid(phoneNumber, length = 10) {
    _expect(TYPE_STRING, phoneNumber);

    if (isTextEmpty(phoneNumber)) {
        return errorCodes.PHONE_NUMBER__EMPTY;
    }

    if (phoneNumber.replace(/[^0-9]/g, "").length !== length) {
        return errorCodes.PHONE_NUMBER__INVALID;
    }

    return true;
}

export function isValidRoutingNumber(routingNumber, length = 9) {
    _expect(TYPE_STRING, routingNumber);

    if (isTextEmpty(routingNumber)) {
        return errorCodes.ROUTING_NUMBER__EMPTY;
    }

    if (routingNumber.replace(/[^0-9]/g, "").length !== length) {
        return errorCodes.ROUTING_NUMBER__INVALID;
    }

    if (hasOnlyZeros(routingNumber)) {
        return errorCodes.ROUTING_NUMBER__ALLZEROS;
    }

    if (!isCheckSumOk(routingNumber)) {
        return errorCodes.ROUTING_NUMBER__INVALID_CHECKSUM;
    }

    return true;
}

export function isValidInstitutionNumber(institutionNumber, length = 3) {
    _expect(TYPE_STRING, institutionNumber);

    if (isTextEmpty(institutionNumber)) {
        return errorCodes.INSTITUTION_NUMBER__EMPTY;
    }

    if (institutionNumber.replace(/[^0-9]/g, "").length !== length) {
        return errorCodes.INSTITUTION_NUMBER__INVALID;
    }

    if (hasOnlyZeros(institutionNumber)) {
        return errorCodes.INSTITUTION_NUMBER__ALLZEROS;
    }

    return true;
}

export function isValidBranchTransitNumber(branchTransitNumber, length = 5) {
    _expect(TYPE_STRING, branchTransitNumber);

    if (isTextEmpty(branchTransitNumber)) {
        return errorCodes.BRANCHTRANSIT_NUMBER__EMPTY;
    }

    if (branchTransitNumber.replace(/[^0-9]/g, "").length !== length) {
        return errorCodes.BRANCHTRANSIT_NUMBER__INVALID;
    }

    if (hasOnlyZeros(branchTransitNumber)) {
        return errorCodes.BRANCHTRANSIT_NUMBER__ALLZEROS;
    }

    return true;
}
export function isValidAccountNumber(accountNumber) {
    _expect(TYPE_STRING, accountNumber);

    const minLengthFora = 7;
    const minlength = 5;

    if (isTextEmpty(accountNumber)) {
        return errorCodes.ACCOUNT_NUMBER__EMPTY;
    }

    if (isForaCredit() &&
        isStringTooShort(accountNumber, minLengthFora)) {
        return errorCodes.ACCOUNT_NUMBER_FORA__INVALID;
    } else if (isStringTooShort(accountNumber, minlength)) {
        return errorCodes.ACCOUNT_NUMBER__INVALID;
    }

    return true;
}

export function isValidUnMaskedAccountNumber(unMaskedAccountNumber) {
    _expect(TYPE_STRING, unMaskedAccountNumber);

    const minLengthFora = 7;
    const minlength = 5;

    if (isTextEmpty(unMaskedAccountNumber)) {
        return errorCodes.ACCOUNT_NUMBER__EMPTY;
    }

    if (!isForaCredit() &&
        isStringTooShort(unMaskedAccountNumber, minlength)) {
        return errorCodes.ACCOUNT_NUMBER__INVALID;
    }

    if (isForaCredit() &&
        isStringTooShort(unMaskedAccountNumber, minLengthFora)) {
        return errorCodes.ACCOUNT_NUMBER_FORA__INVALID;
    }

    if (hasOnlyZeros(unMaskedAccountNumber)) {
        return errorCodes.ACCOUNT_NUMBER__ALLZEROS;
    }

    return true;
}

export function isValidBank(bank, minlength = 2) {
    _expect(TYPE_STRING, bank);

    if (isTextEmpty(bank)) {
        return errorCodes.BANK_NAME__EMPTY;
    }

    if (isStringTooShort(bank, minlength)) {
        return errorCodes.BANK_NAME__INVALID_SHORT;
    }

    if (isLengthGreaterThan(bank, 50)) {
        return errorCodes.BANK_NAME__INVALID_LONG;
    }

    if (!hasLetter(bank)) {
        return errorCodes.BANK_NAME__INVALID_NO_CHAR;
    }

    return true;
}

export function isValidLoanAmount(value, maxAmount = 1000, minAmount = 150) {
    //_expect(TYPE_NUMBER, value);
    if (isTextEmpty(value)) {
        return errorCodes.RAL_LOAN_AMOUNT__INVALID_NO_NUMBER;
    }
    if (hasLetter(value)) {
        return errorCodes.RAL_LOAN_AMOUNT_INVALID_ONLY_NUMBERS;
    }
    if (parseFloat(value) > maxAmount) {
        return errorCodes.RAL_LOAN_AMOUNT_INVALID_GREATER;
    }
    if (parseFloat(value) < minAmount) {
        return errorCodes.RAL_LOAN_AMOUNT_INVALID_LESSER;
    }

    return true;
}

export function isValidSpouceCity(city) {
    if (isTextEmpty(city)) {
        return errorCodes.SPOUCE_CITY__EMPTY;
    }

    if (hasNumber(city)) {
        return errorCodes.SPOUCE_CITY__HAS_NUMBER;
    }

    if (isSpecialCharactersOnly(city)) {
        return errorCodes.SPOUCE_CITY__HAS_CHARACTERS_ONLY;
    }

    return true;
}

export function isValidFileNameSizeMin(fileName) {
    const MIN_FILE_NAME_LENGTH = 3;
    let nameExtTrimmed = fileName.replace(/\.[^/.]+$/, "");

    return nameExtTrimmed.length >= MIN_FILE_NAME_LENGTH;

}

export function isValidFileNameSizeMax(fileName) {
    const MAX_FILE_NAME_LENGTH = 100;

    return fileName.length <= MAX_FILE_NAME_LENGTH;
}

export function isValidFileName(fileName) {
    let match = /^[^.].*[^.]$/;
    let nameExtTrimmed = fileName.replace(/\.[^/.]+$/, "");

    if (isTextEmpty(fileName)) {
        return false;
    }

    return match.exec(nameExtTrimmed);
}

export function isValidFileSize(fileSize) {
    let maxFileSize = "5242880";

    return fileSize <= maxFileSize;
}

export function isValidFileFormat(fileName) {
    let allowedFileFormats = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf|\.bmp)$/i;

    if (isTextEmpty(fileName)) {
        return false;
    }

    return allowedFileFormats.exec(fileName);
}

export function validateFileUpload(fileName, fileSize) {
    if (!isValidFileNameSizeMin(fileName)) {
        return errorCodes.DOCUMENT_UPLOAD__INVALID_FILE_NAME_SIZE_MIN;
    }
    if (!isValidFileNameSizeMax(fileName)) {
        return errorCodes.DOCUMENT_UPLOAD__INVALID_FILE_NAME_SIZE_MAX;
    }
    if (!isValidFileName(fileName)) {
        return errorCodes.DOCUMENT_UPLOAD__INVALID_FILE_NAME;
    }
    if (!isValidFileSize(fileSize)) {
        return errorCodes.DOCUMENT_UPLOAD__INVALID_FILE_SIZE;
    }
    if (!isValidFileFormat(fileName)) {
        return errorCodes.DOCUMENT_UPLOAD__INVALID_FILE_FORMAT;
    }

    return true;
}

export function isPOBox(address){
    var pattern = /\bP(ost|ostal)?([ .]*(O|0)(ffice)?)?([ .]*Box)\b/i;
    return address.match(pattern);
}
